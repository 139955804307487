import { FC } from 'react';
import { ImageProps } from 'next/image';

import { Author } from '@common/clients/api';
import { useTranslation } from '@pxr/i18n';
import { Avatar } from '@web/atoms';
import { InternalLink, Route } from '@web/atoms/InternalLink';

import styles from './AvatarList.module.scss';

export interface Props extends Partial<ImageProps> {
    authors: Author[];
}

export const AvatarList: FC<Props> = ({ authors }) => {
    const __meta = useTranslation('meta').t;
    return (
        <div className={styles.AvatarList}>
            <h3 className={styles.title}>{__meta(`avatar-list.title`)}</h3>
            <div className={styles.authors}>
                {authors.map((author: Author, index: number) => (
                    <div key={index} className={styles.author}>
                        <InternalLink route={Route.User} query={{ slug: author.customSlug }}>
                            <Avatar isRounded src={author.image} />
                            <p> {author.shortName}</p>
                        </InternalLink>
                    </div>
                ))}
            </div>
        </div>
    );
};
