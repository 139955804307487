/**
 * Since we can't switch to the app router yet, this is a fallback file for the pages router
 * See https://pxr-tech.atlassian.net/browse/PB-6306 for more details
 */

import { FC } from 'react';
import dynamic from 'next/dynamic';

import { PlatformID } from '@common/clients/api';
import { prepareProps } from '@web/_app/[contextHash]/prepareProps';
import { Route } from '@web/routing';
import { getPagesStaticPaths, getPagesStaticPropsWrapper } from '@web/routing/getPagesStaticPropsWrapper';
import { HomePage, Props } from '@web/templates/HomePage';

const ViHomePage = dynamic(() => import('@web/templates/HomePage/ViHomePage').then((mod) => mod.ViHomePage));

export const getStaticPaths = getPagesStaticPaths;
export const getStaticProps = getPagesStaticPropsWrapper<Props>(prepareProps, Route.Homepage);

export const Home: FC<Props> = (props) => {
    return props.contextData.platform.id === PlatformID.VI ? (
        <ViHomePage {...props} />
    ) : (
        <HomePage {...props} />
    );
};

export default Home;
